import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Lesson1 from '../pages/B11U1L1/B11U1L1';
import Videos from '../pages/Videos/Videos';



export default function Routes(props) {

  const{user,setReloadApp,myclass,idclass,classname,teachername,teacherid}=props;

 return(

    <Switch>
      <Route path='/Lesson1' exact> 
       <Lesson1/>
      </Route>

       
      <Route path='/Videos' exact>
       <Videos/> 
      </Route> 
     
    
     
    </Switch>
 );

}

