
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
    <div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F740af16a-b1b2-48e2-b192-c453577b7e7c?alt=media&token=1cd0e603-49d6-4d4a-81d9-c7e0e09f97c7"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F54e59b45-d17c-4bbb-b37e-038e0eb389f4?alt=media&token=4bfeab43-8ddf-46ce-98f3-fa50bbc511e8"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F3c3658c8-ce73-4dce-b22f-af9452380a20?alt=media&token=161ca0ca-3b37-4085-9254-96b59fff3a28"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2Fa8ae1eac-3e70-47bf-ab9d-d26454237452?alt=media&token=a7667d61-9be6-428d-9268-4297cae34ea0"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F0407cfea-a7d3-42d8-b257-855ed1176e51?alt=media&token=7fc4b8ca-dbf2-43da-9158-10ff4a3bdc0a"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F8ce49a66-6fdf-446a-8055-ac655d98d506?alt=media&token=09139094-9e1a-499c-90e6-97d27ec76415"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F1ab287ea-396c-4e68-8a69-1a1562e31739?alt=media&token=2bafe732-1b25-462d-b496-7fe51a0e5b54"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F9b1769f0-dea2-4138-b83b-f4c04d9428c7?alt=media&token=47f1a574-66ba-47b3-8b86-6a839c8c7856"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F6a1e16c5-25f5-4c85-b90a-3e77c1d950bf?alt=media&token=7f3e4166-5593-4f2c-b916-3d079bdbdeb8"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2Fc89ce87e-5719-4211-bb11-3408de7712b8?alt=media&token=b3551b93-6674-4e35-a0ac-ad20a00f55ed"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2Ff97840eb-a044-4bee-ad29-a90c593fb5a6?alt=media&token=3faf42e6-0ce3-4d0e-b181-953aa7598a24"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F855b6a54-809a-453e-96e4-168f20f0db9f?alt=media&token=eb93b8b8-ed68-47aa-b406-701b54f0f777"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F4fe93537-5b6e-4175-b0d8-a9239f9050b9?alt=media&token=66b03f1b-a58c-41f3-8bbd-769a79d972ae"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2Fafc6b23b-a0f2-4fd4-a908-b596ba165054?alt=media&token=4fae5195-cc0a-4b69-95f5-09985cdb8189"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2Fc65862d9-0263-4dca-a69d-d6128654b40a?alt=media&token=e901af40-176f-424e-a417-561d106a5a70"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2Fface194e-b413-4b28-bfac-6c93bc5b7c70?alt=media&token=bde32de2-7735-4888-a486-0a7f9bf3c2f1"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F0f324e0d-44ef-4bf9-9159-cd1c5602019b?alt=media&token=3b4f6bd4-3d66-43ca-92cd-ddd49a62b8fc"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F06466005-87c8-4744-bb15-01d962d5b8b8?alt=media&token=ee6f039b-e4ce-4e95-a025-469084430d8b"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F47f26302-9502-45bc-8cb8-82d1a981d1d8?alt=media&token=ff64eb70-05df-4633-a273-f7183709b9e6"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F6fa2c9b4-578b-4454-973a-9c9e17d6bf03?alt=media&token=34a79a05-bad7-4818-b91d-ee863cd38dca"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F119d39bf-b711-4190-9257-bec910792c35?alt=media&token=58392af8-b52c-43b7-8476-301d8e1fc5c7"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2Fe4079d58-c1d8-418a-8ca0-1b5577318cbb?alt=media&token=a7cf7727-3679-44dd-aa24-f04eafd26506"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2F17f30058-bc1c-4314-b6c8-0f52ceb7b5d3?alt=media&token=ebe14383-5ae7-49de-9d3a-21e9627e3d67"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2Fa99feae5-f56c-420a-9169-0eeacf1d8cd4?alt=media&token=917554ce-c594-4728-b846-a3257278671b"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
          

        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU4ES%2FDIVU4ESMAT.pdf?alt=media&token=3d0c1b2f-eb2e-4fec-8046-a747549773d1' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
